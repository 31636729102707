import React from "react";
import "./Home.css";
import PortfolioWork from "../Pages/Portfolio/PortfolioWork";
import { NavLink } from "react-router-dom";
import Aos from "aos";

function PortfolioCompCall() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <PortfolioWork />
      <div className="homePortfolio">
        <NavLink to="/portfolio" data-aos="fade-up">
          DISCOVER MORE
        </NavLink>
      </div>
    </>
  );
}

export default PortfolioCompCall;
