import React from 'react';
import './SoftwareServices.css';
import {
  AiOutlineAntDesign,
  // AiFillApple,
  // AiFillProject,
  // AiOutlineYoutube,
} from 'react-icons/ai';
// import { FaGamepad } from "react-icons/fa";
// import { CgDesignmodo } from "react-icons/cg";
import {NavLink} from 'react-router-dom';
import Aos from 'aos';

function SoftwareServices() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <div id="softwareServices">
        <div className="softwareServices-main">
          <h1 data-aos="zoom-in">
            BEST-IN-CLASS SOFTWARE DEVELOPMENT <span>SERVICES</span>
          </h1>
          <p data-aos="zoom-in">
            MH Global Communication LLC is a best software development and
            mobile applications company.
          </p>
          <div className="service-card">
            {/* card 01 */}
            <div className="card-main" data-aos="fade-up">
              <div className="card-title">
                <h2>01</h2>
                <AiOutlineAntDesign className="web" />
              </div>
              <NavLink to="/digital-marketing">Digital Marketing</NavLink>
            </div>

            {/* card 02 */}
            <div className="card-main" data-aos="fade-up">
              <div className="card-title">
                <h2>02</h2>
                <AiOutlineAntDesign className="web" />
              </div>
              <NavLink to="/website-development">Website Development</NavLink>
            </div>

            {/* card 03 */}
            <div className="card-main" data-aos="fade-up">
              <div className="card-title">
                <h2>03</h2>
                <AiOutlineAntDesign className="web" />
              </div>
              <NavLink to="/application-development">
                Application Development
              </NavLink>
            </div>

            {/* card 04 */}
            <div className="card-main" data-aos="fade-up">
              <div className="card-title">
                <h2>04</h2>
                <AiOutlineAntDesign className="web" />
              </div>
              <NavLink to="/seo">Seo</NavLink>
            </div>
            {/* card 05 */}

            <div className="card-main" data-aos="fade-up">
              <div className="card-title">
                <h2>05</h2>
                <AiOutlineAntDesign className="web" />
              </div>
              <NavLink to="#">E-commerce Webstore</NavLink>
            </div>

            {/* card 06 */}
            <div className="card-main" data-aos="fade-up">
              <div className="card-title">
                <h2>06</h2>
                <AiOutlineAntDesign className="web" />
              </div>
              <NavLink to="#">Graphic Designing</NavLink>
            </div>

            {/* card 07 */}
            <div className="card-main" data-aos="fade-up">
              <div className="card-title">
                <h2>07</h2>
                <AiOutlineAntDesign className="web" />
              </div>
              <NavLink to="#">Marketing Consultency</NavLink>
            </div>

            {/* card 08 */}
            <div className="card-main" data-aos="fade-up">
              <div className="card-title">
                <h2>08</h2>
                <AiOutlineAntDesign className="web" />
              </div>
              <NavLink to="#">BPO</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SoftwareServices;
