import MaleUser from "../../../assets/MaleUser.png";
import FemaleUser from "../../../assets/FemaleUser.png";

export const TeamMembersApi = [
  {
    pic: MaleUser,
    name: "Asad Kazmi",
    role: "Web Designer",
  },
  {
    pic: FemaleUser,
    name: "Mishal",
    role: "Graphic Designer",
  },
  {
    pic: MaleUser,
    name: "Muzammil",
    role: "Digital Marketer",
  },
  {
    pic: MaleUser,
    name: "Saim",
    role: "React Native Developer",
  },
  {
    pic: MaleUser,
    name: "Qaisar Raza",
    role: "ReactJs Developer",
  },
  {
    pic: MaleUser,
    name: "Umar Maqsood",
    role: "ReactJs Developer",
  },
];
