import React from "react";
import "./AboutCompany.css";
import { BsChevronRight } from "react-icons/bs";
import logos from "../../../assets/logo2.png";
import Aos from "aos";
function AboutCompany() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <div id="aboutCompany">
        <div className="about-detail" data-aos="fade-right">
          <h1>ABOUT OUR COMPANY</h1>
          <h2>Refund Policy</h2>
          <p>
             We offer refunds under the following circumstances:

If we fail to deliver the purchased service according to the specifications outlined in the agreement.
If the service provided is defective or does not meet the quality standards expected by the customer.
If the customer requests a refund within the timeframe specified in the service agreement or on our website.   </p>
          <p>
          To request a refund, please contact our customer support team  within 14 days of purchasing the service. Please provide your order details and a clear explanation of why you are requesting a refund  .
          </p>
        </div>
        <div className="about-value" data-aos="fade-left">
          <h1>WHAT WE DO</h1>
          <p>
            We perform our best in Digital Marketing for business enhancement on
            customer demand. We deliver all the services and solutions related
            to the Digital Marketing field. Our professionals also worked in
            content creation for their beloved customers. We deal in different
            domains:
          </p>
          <div className="aboutPoint">
            <img src={logos} alt="" style={{ height: "80px" }} />
            <div className="point-main">
              <div className="points">
                <BsChevronRight className="gre" />
                <p>Digital Marketing</p>
              </div>
              <div className="points">
                <BsChevronRight className="gre" />
                <p>Graphic Designing</p>
              </div>
              <div className="points">
                <BsChevronRight className="gre" />
                <p>Web Development</p>
              </div>
              <div className="points">
                <BsChevronRight className="gre" />
                <p>App Development</p>
              </div>
              <div className="points">
                <BsChevronRight className="gre" />
                <p>Seo</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutCompany;
