import React from "react";
import "../../DigitalMarketing/DigitalMarketinDetails/DigitalMarketinDetails.css";
import { buttons } from "../WebsiteApi";
import { useNavigate } from "react-router-dom";
import Aos from "aos";

function WebsiteDetails() {
  Aos.init();
  Aos.refresh();
  const Navigate = useNavigate();
  const sendData = (data) => {
    Navigate("/website-development-packages", { state: data });
  };
  return (
    <div id="diMarDetails">
      <h1>
        WEBSITE DEVELOPMENT <span>SERVICES</span>
      </h1>
      <div className="diContainer">
        {buttons.map((data, index) => (
          <div className="cards" key={index}>
            <h5>{data.title}</h5>
            <p>{data.a}</p>
            <p>{data.b}</p>
            <p>{data.c}</p>

            <button onClick={() => sendData(data)}>See Packages</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WebsiteDetails;
