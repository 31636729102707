import React from 'react';
import './About.css';
import {NavLink} from 'react-router-dom';
import Aos from 'aos';
import AboutCompany from './AboutCompany/AboutCompany';
import SoftwareServices from '../../Home/SoftwareServices/SoftwareServices';
import NextProject from '../../Home/NextProject/NextProject';
import CompanyMember from './CompanyMember/CompanyMember';
import Counter from './Counter/Counter';
import {Helmet} from 'react-helmet';

function About() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | MH Global Communication LLC</title>
        <meta
          name="description"
          content="MH Global Communication LLC offers professional web development and provides consulting services in the area of Digital Marketing and
          also provides automated and IT-enabled solutions to businesses across the globe. We provide a wide range of consulting services"
        />
        <meta
          name="keywords"
          content="MH Global Communication LLC, digitalmarketing, digitalmarketingservice, webdevelopment, appdevelopment, itservices, seoservices, sales, business, marketingonline, marketingagency, digitalmarketingexperts, socialmediamarketing, socialmediamarketingagency, seo, reactjs, frontendwebdevelopment, frontenddeveloper, staticwebsite, ecommercewebsite, ecommercestore, software, graphicdesiging, logo, logodesign, bannerdesign, posterdesign, contentwriting, bootstrap, tailwindcss, html5, css3, html, css, nodejs, expressjs, reactnative, mongodb, solutions allitservices, smm,"
        />
        <link rel="canonical" href="http://mhglobalcommunicationllc.com" />
      </Helmet>
      <div id="about">
        <div className="overlay">
          <div className="breadcrumb">
            <h1 data-aos="fade-down">ABOUT US</h1>
            <div className="d-flex">
              <NavLink to="/" data-aos="fade-right">
                HOME
              </NavLink>
              <div className="ms-2">-</div>
              <p className="actv ms-2" data-aos="fade-left">
                ABOUT US
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SoftwareServices />
        <AboutCompany />
        <CompanyMember />
        <Counter />
        <NextProject />
      </div>
    </>
  );
}

export default About;
