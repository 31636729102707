import React, {useState, useEffect} from 'react';
import './Navbar.css';
import Logo from '../assets/logo2.png';
import {NavLink, Link} from 'react-router-dom';
import {BsChatDotsFill} from 'react-icons/bs';
import {VscMenu} from 'react-icons/vsc';
import {GrClose} from 'react-icons/gr';

function Navbar() {
  const [menu, setMenu] = useState(false);
  const [navFixer, setNavFixer] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setNavFixer(true);
      } else {
        setNavFixer(false);
      }
    });
  }, []);
  return (
    <nav id="nav">
      <div className={navFixer ? 'navBar fixer' : 'navBar'}>
        <div className="logo">
          <Link to="/" className="logoLink">
            <p className="logoText">MH Global Communication LLC</p>
          </Link>
        </div>
        <VscMenu className="openMenu" onClick={() => setMenu(true)} />
        <div
          className={menu ? 'rightBoxOverlay show-overlay' : 'rightBoxOverlay'}
          onClick={() => setMenu(false)}
        ></div>
        <div className={menu ? 'nav-items show-nav' : 'nav-items'}>
          <GrClose className="closeMenu" onClick={() => setMenu(false)} />
          <li>
            <NavLink to="/" end onClick={() => setMenu(false)}>
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={() => setMenu(false)}>
              ABOUT US
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" onClick={() => setMenu(false)}>
              SERVICES
            </NavLink>
          </li>
          <li>
            <NavLink to="/portfolio" onClick={() => setMenu(false)}>
              PORTFOLIO
            </NavLink>
          </li>
          <li>
            <NavLink to="/careers" onClick={() => setMenu(false)}>
              CAREERS
            </NavLink>
          </li>
          <li>
            <NavLink to="/blog" onClick={() => setMenu(false)}>
              BLOG
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={() => setMenu(false)}>
              CONTACT
            </NavLink>
          </li>
          <div className="navDetails">
            <p>
              MH Global Communication LLC is a bespoke software development
              company specializing in research-led, human-centered custom
              software development, progressive web apps (PWA's), and mobile
              applications.
            </p>
            <div className="mt-2">
              <a
                href="mailTo: contact@mhglobalcommunicationllc.com"
                target="_blank"
                rel="noreferrer"
              >
                contact@mhglobalcommunicationllc.com
              </a>
            </div>
            <div className="mt-2">
              <a
                href="https://wa.me/+16465127073

"
                target="_blank"
                rel="noreferrer"
              >
                +1 (646) 512-7073
              </a>
            </div>
          </div>
        </div>
        <a
          href="https://wa.me/+16465127073"
          target="_blank"
          rel="noreferrer"
          className="contactBtn"
        >
          <BsChatDotsFill className="icon" />
          <div className="ms-2">
            <span>CONNECT WITH US</span>
            <p>+1 (646) 512-7073</p>
          </div>
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
