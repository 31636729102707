import React from 'react';
import './ContactUsModal1.css';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import logo from '../assets/logo2.png';
import {toast} from 'react-toastify';
import Loading from '../../Loading/Loading';
import axios from 'axios';
import {useFormik} from 'formik';
import {ContactUsModal1Schema} from './ContactUsModal1Schema';
import {useState} from 'react';
import {Helmet} from 'react-helmet';

function ContactUsModal1() {
  const navigate = useNavigate();
  const goback = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  const location = useLocation();
  const filterDetails = location.state.data;
  const packageName = location.state.packageName;
  const packageTitle = location.state.packageTitle;
  const initialValues = {
    name: '',
    city: '',
    phone: '',
    webUrl: '',
    packageName: packageName,
    packageTitle: packageTitle,
    packageType: filterDetails.title,
  };

  const [loading, setLoading] = useState(false);
  const {values, errors, touched, handleBlur, handleChange, handleSubmit} =
    useFormik({
      initialValues,
      validationSchema: ContactUsModal1Schema,
      onSubmit: (values, action) => {
        setLoading(true);
        axios
          .post('https://thinko.herokuapp.com/getPackage', values)
          .then((res) => {
            toast.success('Your message has been successfully Sent!');
            setLoading(false);
            action.resetForm();
          })
          .catch((err) => {
            console.log('Error', err.message);
            setLoading(false);
            toast.error('Something wrent wrong, please try again!');
          });
      },
    });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {packageName} | {packageTitle} | {filterDetails.title} | MH Global
          Communication LLC
        </title>
        <meta
          name="description"
          content="MH Global Communication LLC offers professional web development and provides consulting services in the area of Digital Marketing and
          also provides automated and IT-enabled solutions to businesses across the globe. We provide a wide range of consulting services"
        />
        <meta
          name="keywords"
          content="MH Global Communication LLC, digitalmarketing, digitalmarketingservice, webdevelopment, appdevelopment, itservices, seoservices, sales, business, marketingonline, marketingagency, digitalmarketingexperts, socialmediamarketing, socialmediamarketingagency, seo, reactjs, frontendwebdevelopment, frontenddeveloper, staticwebsite, ecommercewebsite, ecommercestore, software, graphicdesiging, logo, logodesign, bannerdesign, posterdesign, contentwriting, bootstrap, tailwindcss, html5, css3, html, css, nodejs, expressjs, reactnative, mongodb, solutions allitservices, smm,"
        />
        <link rel="canonical" href="http://mhglobalcommunicationllc.com" />
      </Helmet>
      <div id="contactModal">
        <Link to="/">
          <p className="logoText">MH Global Communication LLC</p>
          {/* <img src={logo} alt="Thinko Digital" /> */}
        </Link>
        <div className="contactContainer">
          <h3>
            Thanks for choosing <span>MH Global Communication LLC</span>. We
            need your information:
          </h3>
          <form>
            <div className="inputField">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name ? (
                <p className="form-error">{errors.name}</p>
              ) : null}
            </div>
            <div className="inputField mt-3">
              <label htmlFor="city">City:</label>
              <input
                type="text"
                placeholder="City"
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.city && touched.city ? (
                <p className="form-error">{errors.city}</p>
              ) : null}
            </div>
            <div className="inputField mt-3">
              <label htmlFor="number">Phone Number:</label>
              <input
                type="number"
                placeholder="Phone Number"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && touched.phone ? (
                <p className="form-error">{errors.phone}</p>
              ) : null}
            </div>
            {packageName === 'Seo' ? (
              <div className="inputField mt-3">
                <label htmlFor="url">Website Url:</label>
                <input
                  type="text"
                  placeholder="Website Url"
                  name="webUrl"
                  value={values.webUrl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.webUrl && touched.webUrl ? (
                  <p className="form-error">{errors.webUrl}</p>
                ) : null}
              </div>
            ) : (
              ''
            )}
            <div className="btns">
              <button type="" onClick={goback}>
                Back
              </button>
              <button type="submit" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="mt-4">{loading ? <Loading /> : <></>}</div>
      </div>
    </>
  );
}

export default ContactUsModal1;
