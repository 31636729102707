import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WithNav from "./WithNav";
import WithOutNav from "./WithOutNav";
import Home from "../Home/Home";
import About from "../Pages/About/About";
import Services from "../Pages/Services/Services";
import Portfolio from "../Pages/Portfolio/Portfolio";
import Careers from "../Pages/Careers/Careers";
import Contact from "../Pages/Contact/Contact";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import ScrollToTop from "./ScrollToTop";
import DigitalMarketing from "../SubPages/DigitalMarketing/DigitalMarketing";
import DigitalMarketingPackages from "../SubPages/DigitalMarketing/DigitalMarketinDetails/DigitalMarketingPackages";
import Website from "../SubPages/Website/Website";
import WebsitePackages from "../SubPages/Website/WebsiteDetails/WebsitePackages";
import ContactUsModal1 from "../Modals/ContactUsModal1";
import Application from "../SubPages/Application/Application";
import ApplicationPackages from "../SubPages/Application/ApplicationDetails/ApplicationPackages";
import Seo from "../SubPages/Seo/Seo";
import ScrollToTopButton from "./ScrollToTopButton";

function Routing() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<WithNav />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/digital-marketing" element={<DigitalMarketing />} />
            <Route
              path="/digital-marketing-packages"
              element={<DigitalMarketingPackages />}
            />
            <Route path="/website-development" element={<Website />} />
            <Route
              path="/website-development-packages"
              element={<WebsitePackages />}
            />
            <Route path="/application-development" element={<Application />} />
            <Route
              path="/application-development-packages"
              element={<ApplicationPackages />}
            />
            <Route path="/seo" element={<Seo />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
          <Route element={<WithOutNav />}>
            <Route path="*" element={<ErrorPage />} />
            <Route path="/get-package" element={<ContactUsModal1 />} />
          </Route>
        </Routes>
        <ScrollToTop />
        <ScrollToTopButton />
      </Router>
    </>
  );
}

export default Routing;
