import React from "react";
import "./IndustryServe.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import {
  FaTruck,
  FaHospitalAlt,
  FaPlane,
  FaHeartbeat,
  FaUserGraduate,
  FaShoppingBag,
} from "react-icons/fa";
import { ImHome3 } from "react-icons/im";

function IndustryServe() {
  return (
    <div id="industryServe">
      <h1 data-aos="zoom-in">
        INDUSTRIES WE <span>SERVE</span>
      </h1>
      <div className="ind-swiper">
        <Swiper
          modules={[Pagination, Autoplay]}
          slidesPerView={3}
          spaceBetween={10}
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
              slidesPerGroup: 1,
            },
            600: {
              slidesPerView: 1,
              spaceBetween: 10,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
              slidesPerGroup: 2,
            },
            800: {
              slidesPerView: 3,
              spaceBetween: 30,
              slidesPerGroup: 3,
            },
          }}
          data-aos="fade-up"
        >
          <SwiperSlide>
            <div className="client-det">
              <FaTruck className="icon" />
              <h5>
                Logistics
                <br />
                Industry
              </h5>
              <p>Integrating technology to improve supply chain processess.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client-det">
              <FaHospitalAlt className="icon" />
              <h5>
                Hospitality <br />
                Industry
              </h5>
              <p>
                Create top-notch hotel environments using the latest
                technologies.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client-det">
              <FaPlane className="icon" />
              <h5>
                Tourism <br />
                Industry
              </h5>
              <p>We change travel experiences with custom IT solutions.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client-det">
              <ImHome3 className="icon" />
              <h5>
                Real Estate <br />
                Industry
              </h5>
              <p>
                Scalable and riveting resources to help forward your strategies.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client-det">
              <FaHeartbeat className="icon" />
              <h5>
                Healthcare <br />
                Industry
              </h5>
              <p>
                Improve patient-to-doctor care quality with smart IT systems.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client-det">
              <FaUserGraduate className="icon" />
              <h5>
                Education
                <br />
                Industry
              </h5>
              <p>Reshaping educational experiences with custom softwares.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client-det">
              <FaShoppingBag className="icon" />
              <h5>
                E-Commerce
                <br />
                Industry
              </h5>
              <p>
                Build digitally sound content management systems with stellar
                UI.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default IndustryServe;
