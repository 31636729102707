import React from 'react';
import './Jobs.css';
import Aos from 'aos';

function Jobs() {
  Aos.init();
  Aos.refresh();
  return (
    <div id="jobs">
      <h1 data-aos="zoom-in">
        OPEN <span>POSITIONS</span>
      </h1>
      <div className="careersContainer">
        <div className="left-box">
          <p>
            We don't have any open position right now! Keep visiting our website
            to spot an open position and apply away.
          </p>
        </div>
        <div className="right-box">
          <div>
            <h3>HOW TO APPLY</h3>
            <p>
              Send your cv, relevant work experience and anything else that will
              make you stand out to contact@mhglobalcommunicationllc.com
            </p>
          </div>
          <div>
            <h3>WHY US</h3>
            <p>
              We are an awward winning company. Job opportunities are like
              buses, there’s always another one coming. But the most important
              factor for you is that your desicion to choose a best place. MH
              Global Communication LLC is exact a platform which you dream.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
