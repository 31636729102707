import React from 'react';
import './DigitalMarketinDetails.css';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import Aos from 'aos';
import {Helmet} from 'react-helmet';

function DigitalMarketingPackages() {
  Aos.init();
  Aos.refresh();
  const location = useLocation();
  const filterDetails = location.state;
  const Navigate = useNavigate();
  const sendDataToForm = (data) => {
    Navigate('/get-package', {
      state: {
        data,
        packageName: 'Digital Marketing',
        packageTitle: filterDetails.title,
      },
    });
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{filterDetails.title} | MH Global Communication LLC</title>
        <meta
          name="description"
          content="MH Global Communication LLC offers professional web development and provides consulting services in the area of Digital Marketing and
          also provides automated and IT-enabled solutions to businesses across the globe. We provide a wide range of consulting services"
        />
        <meta
          name="keywords"
          content="MH Global Communication LLC, digitalmarketing, digitalmarketingservice, webdevelopment, appdevelopment, itservices, seoservices, sales, business, marketingonline, marketingagency, digitalmarketingexperts, socialmediamarketing, socialmediamarketingagency, seo, reactjs, frontendwebdevelopment, frontenddeveloper, staticwebsite, ecommercewebsite, ecommercestore, software, graphicdesiging, logo, logodesign, bannerdesign, posterdesign, contentwriting, bootstrap, tailwindcss, html5, css3, html, css, nodejs, expressjs, reactnative, mongodb, solutions allitservices, smm,"
        />
        <link rel="canonical" href="http://mhglobalcommunicationllc.com" />
      </Helmet>
      <div id="diPackages">
        <div className="overlay">
          <div className="breadcrumb">
            <h1 data-aos="fade-down">{filterDetails.title}</h1>
            <div className="d-flex flex-wrap justify-content-center">
              <NavLink to="/" data-aos="fade-right">
                HOME
              </NavLink>
              <div className="ms-2">-</div>
              <NavLink to="/services" className="ms-2" data-aos="fade-right">
                SERVICES
              </NavLink>
              <div className="ms-2">-</div>
              <NavLink
                to="/digital-marketing"
                className="ms-2"
                data-aos="fade-right"
              >
                DIGITAL MARKETING
              </NavLink>
              <div className="ms-2">-</div>
              <p className="actv ms-2" data-aos="fade-left">
                {filterDetails.title}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="diPackagesDetails">
        <h1>{filterDetails.title}</h1>
        <div className="pContainer">
          {filterDetails.packages.map((data, index) => (
            <div className="cards" key={index}>
              <h5>{data.title}</h5>
              <p>Charges: {data.charges}</p>
              <div className="cap-box">
                <div className="cap-inner">
                  <h5>{data.details.title}</h5>
                  <p>{data.details.a}</p>
                  <p>{data.details.b}</p>
                  <p>{data.details.c}</p>
                  <p>{data.details.d}</p>
                  <p>{data.details.e}</p>
                  <p>{data.details.f}</p>
                  <p>Charges: {data.details.charges}</p>
                  <button onClick={() => sendDataToForm(data)}>
                    Get Package
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default DigitalMarketingPackages;
