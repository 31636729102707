import React from 'react';
import './Location.css';
import Aos from 'aos';

function Location() {
  Aos.init();
  Aos.refresh();
  return (
    <div id="location">
      <h1 data-aos="zoom-in">
        OUR OFFICE <span>LOCATION</span>
      </h1>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.0775201878414!2d-73.81969!3d40.7603197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26017eaf5c7b7%3A0x62d16c637309af55!2s144-43%20Barclay%20Ave%2C%20Flushing%2C%20NY%2011355%2C%20USA!5e0!3m2!1sen!2s!4v1702708158819!5m2!1sen!2s"
          allowfullscreen=""
          loading="lazy"
          title="MH Global Communication LLC"
        ></iframe>
      </div>
    </div>
  );
}

export default Location;
