import React from 'react';
import './Slider.css';
import Aos from 'aos';
import {NavLink} from 'react-router-dom';

function Slider() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <div id="slider">
        <div className="slideShow">
          <div className="overlay">
            <div className="time">
              <div>MON - FRI</div>
              <div className="ms-3">9AM - 4:30PM</div>
            </div>
            <div
              className="layer1"
              data-aos="fade-down"
              data-aos-duration="3000"
            ></div>
            <div
              className="layer2"
              data-aos="fade-left"
              data-aos-duration="2000"
            ></div>
            <div
              className="layer3"
              data-aos="fade-left"
              data-aos-duration="3000"
            ></div>
            <div className="details">
              <p data-aos="fade-down">DREAM, CONNECT, INNOVATE</p>
              <h1 data-aos="fade-down">MH Global</h1>
              <h1 data-aos="fade-down">Communication LLC</h1>
              <NavLink to="/about" data-aos="fade-up" data-aos-duration="1000">
                DISCOVER MORE
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Slider;
