import React from 'react';
import {Link} from 'react-router-dom';
import './ErrorPage.css';
import {Helmet} from 'react-helmet';
import Aos from 'aos';

function ErrorPage() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Not Found | MH Global Communication LLC</title>
        <meta
          name="description"
          content="MH Global Communication LLC offers professional web development and provides consulting services in the area of Digital Marketing and
          also provides automated and IT-enabled solutions to businesses across the globe. We provide a wide range of consulting services"
        />
        <meta
          name="keywords"
          content="MH Global Communication LLC, digitalmarketing, digitalmarketingservice, webdevelopment, appdevelopment, itservices, seoservices, sales, business, marketingonline, marketingagency, digitalmarketingexperts, socialmediamarketing, socialmediamarketingagency, seo, reactjs, frontendwebdevelopment, frontenddeveloper, staticwebsite, ecommercewebsite, ecommercestore, software, graphicdesiging, logo, logodesign, bannerdesign, posterdesign, contentwriting, bootstrap, tailwindcss, html5, css3, html, css, nodejs, expressjs, reactnative, mongodb, solutions allitservices, smm,"
        />
        <link rel="canonical" href="http://mhglobalcommunicationllc.com" />
      </Helmet>
      <div id="errorPage">
        <div className="pageContent">
          <h1 data-aos="fade-down">404</h1>
          <h4 data-aos="zoom-in">Not Found</h4>
          <p data-aos="zoom-in">
            Oops! We can't find the page you're looking for.
          </p>
          <Link to="/" data-aos="fade-up">
            Go to Homepage
          </Link>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
