import React from 'react';
import './Footer.css';
import logo from '../assets/logo2.png';
import {MdLocationOn, MdEmail} from 'react-icons/md';
import {IoIosCall} from 'react-icons/io';
import {FaWhatsapp} from 'react-icons/fa';
import {BsFacebook, BsLinkedin, BsInstagram, BsPinterest} from 'react-icons/bs';

function Footer() {
  return (
    <footer>
      <div className="f-box">
        <div className="inner-box">
          <div className="logo">
            <p className="logoText">MH Global Communication LLC</p>
            {/* <img src={logo} alt="THinko Digital" /> */}
          </div>
          <p className="mt-4">
            MH Global Communication LLC is a bespoke software development
            company specializing in research-led, human-centered custom software
            development.
          </p>
        </div>
        <div className="inner-box">
          <h2>CONTACT</h2>
          <div className="mt-4">
            <li>
              <MdLocationOn className="icon" />
              <a
                href="https://goo.gl/maps/JuKJbS2aBkf6GxLo7"
                target="_blank"
                rel="noreferrer"
              >
                144-43 BARCLAYS AVE FLUSHING NY 11354
              </a>
            </li>
            <li>
              <IoIosCall className="icon" />
              <a href="tel: +16465127073" target="_blank" rel="noreferrer">
                +1 (646) 512-7073
              </a>
            </li>
            <li>
              <FaWhatsapp className="icon" />
              <a
                href="https://wa.me/+16465127073"
                target="_blank"
                rel="noreferrer"
              >
                +1 (646) 512-7073
              </a>
            </li>
            <li>
              <MdEmail className="icon" />
              <a
                href="mailTo: contact@mhglobalcommunicationllc.com"
                target="_blank"
                rel="noreferrer"
              >
                contact@mhglobalcommunicationllc.com
              </a>
            </li>
          </div>
          {/* <div className="social-icons">
            <a
              href="https://www.facebook.com/thinkodigitalsolution"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook className="fa" />
            </a>
            <a
              href="https://www.instagram.com/thinkodigital/"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram className="fa" />
            </a>
            <a
              href="https://www.linkedin.com/company/thinkodigitalmarketing/"
              target="_blank"
              rel="noreferrer"
            >
              <BsLinkedin className="fa" />
            </a>
            <a
              href="https://www.pinterest.com/thinkodigital/?invite_code=c67708eb2dd24a8a9264b55b29e72aa6&sender=985655205855053523"
              target="_blank"
              rel="noreferrer"
            >
              <BsPinterest className="fa" />
            </a>
            <a
              href="https://wa.me/+16465127073"
              target="_blank"
              rel="noreferrer"
            >
              <FaWhatsapp className="fa" />
            </a>
          </div> */}
        </div>
      </div>
      <div className="line"></div>
      <p>Dream | Connect | MH Global Communication LLC. © 2022</p>
    </footer>
  );
}

export default Footer;
