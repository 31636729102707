export const buttons = [
  {
    id: 1,
    title: 'Android & IOS App',
    a: 'E-commerce Store',
    b: 'Services App',
    c: 'Hybrid App',
    packages: [
      {
        id: 1,
        title: 'Silver Package',
        charges: '550 ',
        details: {
          title: 'Silver Package',
          a: '550 USD Service',
          b: 'Extra charges will be added according to functionality',
          charges: '550 USD',
        },
      },
    ],
  },
  {
    id: 2,
    title: 'Desktop App',
    a: 'E-commerce Store',
    b: 'Services App',
    c: 'Hybrid App',
    packages: [
      {
        id: 1,
        title: 'Silver Package',
        charges: '600 USD',
        details: {
          title: 'Silver Package',
          a: '600 USD Service',
          b: 'Extra charges will be added according to functionality',
          charges: '600 USD',
        },
      },
    ],
  },
];
