import React from "react";
import "./NextProject.css";
import Aos from "aos";
import { NavLink } from "react-router-dom";
function NextProject() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <div id="nextProject">
        <div className="project" data-aos="flip-down" data-aos-duration="2000">
          <h1>
            LET'S TALK ABOUT YOUR NEXT <br />
            PROJECT!
          </h1>
          <NavLink to="/contact">GET STARTED</NavLink>
        </div>
      </div>
    </>
  );
}

export default NextProject;
