import React from "react";
import "../../DigitalMarketing/DigitalMarketinDetails/DigitalMarketinDetails.css";
import { buttons } from "../SeoApi";
import { useNavigate } from "react-router-dom";
import Aos from "aos";

function SeoDetails() {
  Aos.init();
  Aos.refresh();
  const Navigate = useNavigate();
  const sendDataToForm = (data) => {
    Navigate("/get-package", {
      state: { data, packageName: "Seo", packageTitle: "Seo Package" },
    });
  };
  return (
    <>
      <div id="diMarDetails">
        <h1>
          SEO <span>SERVICES</span>
        </h1>
        <div className="diContainer">
          {buttons.map((data, index) => (
            <div className="cards" key={index}>
              <h5>{data.title}</h5>
              <p>{data.a}</p>
              <p>{data.b}</p>
              <p>{data.c}</p>
              <p>{data.d}</p>
              <button onClick={() => sendDataToForm(data)}>Get Package</button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SeoDetails;
