import MaleUser from '../../assets/MaleUser.png';
import FemaleUser from '../../assets/FemaleUser.png';

export const ClientSaysApi = [
  {
    pic: MaleUser,
    name: 'Adnan Aurangzaib',
    role: 'Sr. Manager',
    des: 'MH Global Communication LLC provides us complete solutions regarding digital marketing and they prove themselves. They provide me the services of web development at a very nominal price in the given time period. They satisfied me with all aspects and they give reports on a daily basis. I can congratulate all the team of MH Global Communication LLC for your good work.',
  },
  {
    pic: FemaleUser,
    name: 'Sarah Arian',
    role: 'Chief Executive',
    des: 'I am really satisfied with MH Global Communication LLC and recmond to those who are looking for digital marketing experts, I think this is the best platform. They provide us the service of content writing and they do a good job in this specific field. The content is very unique and useful for our website',
  },
  {
    pic: MaleUser,
    name: 'Sajjad Ahmed',
    role: 'Sales Manager',
    des: "MH Global Communication LLC is a beautiful platform in the digital world. They contribute very well in the digital industry with a serious attitude. This agency gives me a service of graphic designing as well as consultancy. The consultant's attitude was very impressive and he is a very nice person. Thanks to MH Global Communication LLC for providing such trusty solutions and helping to achieve goals.",
  },
];
