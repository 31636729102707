import web1 from "../../assets/portfolio/web1.png";
import web2 from "../../assets/portfolio/web2.png";
import web3 from "../../assets/portfolio/web3.png";

export const PortfolioApi = [
  {
    pic: web1,
    projectName: "Inrealtor",
    url: "https://inrealtor.pk/",
    category: "Website",
  },
  {
    pic: web2,
    projectName: "Panthar Dispatch Services",
    url: "https://panthar-dispatch.vercel.app/",
    category: "Website",
  },
  {
    pic: web3,
    projectName: "Bharmar eCommerce Store",
    url: "https://bharmar-store.vercel.app/",
    category: "Website",
  },
];
