import React from "react";
import "./WhyUs.css";
import img2 from "../../assets/image-7.jpg";
import Aos from "aos";

function WhyUs() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <div id="whyUs">
        <h1 data-aos="zoom-in">
          WHY CHOOSE US <span>?</span>
        </h1>
        {/* section 01 */}
        <div className="whyus-main">
          <div className="whyus-sub">
            {/* detail card 01  */}
            <div className="whyus-detail" data-aos="fade-right">
              <div className="number">
                <p>01</p>
              </div>
              <div className="whyus-text">
                <h4>TONS OF FEATURES</h4>
                <p>
                  We give you our professionals and they apply all the features
                  of your respective bussiness.
                </p>
                <hr />
              </div>
            </div>
            {/* detail card 02 */}
            <div className="whyus-detail" data-aos="fade-right">
              <div className="number">
                <p>02</p>
              </div>
              <div className="whyus-text">
                <h4>POWERPACK THEME</h4>
                <p>
                  Our services are client based and they have the right to
                  customise any data or site adjustment.
                </p>
                <hr />
              </div>
            </div>
            {/* detail card 03  */}
            <div className="whyus-detail" data-aos="fade-right">
              <div className="number">
                <p>03</p>
              </div>
              <div className="whyus-text">
                <h4>DAY NIGHT SUPPORT</h4>
                <p>
                  24/7 our support and services are available for our customers.
                </p>
                <hr />
              </div>
            </div>
          </div>
          {/* section 02  */}
          <div className="whyus-picture" data-aos="fade-left">
            <img src={img2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyUs;
