export const buttons = [
  {
    id: 1,
    title: 'Social Media Marketing',
    packages: [
      {
        id: 1,
        title: 'Silver Package',
        charges: '300 USD',
        details: {
          title: 'Silver Package',
          a: '12 Posts/Month',
          b: 'Content Creation',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '1 Ad',
          f: 'Management',
          charges: '300 USD',
        },
      },
      {
        id: 2,
        title: 'Golden Package',
        charges: '500 USD',
        details: {
          title: 'Golden Package',
          a: '20 Posts/Month',
          b: '1 Video',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '2 Ads',
          f: 'Management',
          g: 'Content Creation',
          charges: '500 USD',
        },
      },
      {
        id: 3,
        title: 'Platinum Package',
        charges: '800 USD',
        details: {
          title: 'Platinum Package',
          a: '30 Static Posts',
          b: '2 Videos',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '3 Ads',
          f: 'Management',
          g: 'Content Creation',
          charges: '800 USD',
        },
      },
    ],
  },
  {
    id: 2,
    title: 'YouTube Ads',
    packages: [
      {
        id: 1,
        title: 'Silver Package',
        charges: '150 USD',
        details: {
          title: 'Silver Package',
          a: '2 Ads on Google',
          b: 'Keywords Research',
          c: 'Optimization',
          d: 'Monthly Reporting',
          e: 'Google Business Manager',
          charges: '150 USD',
        },
      },
      {
        id: 2,
        title: 'Golden Package',
        charges: '300 USD',
        details: {
          title: 'Golden Package',
          a: '4 Ads on Google',
          b: 'Keywords Research',
          c: 'Optimization',
          d: 'Monthly Reporting',
          e: 'Google Business Manager',
          charges: '300 USD',
        },
      },
      {
        id: 3,
        title: 'Platinum Package',
        charges: '500 USD',
        details: {
          title: 'Platinum Package',
          a: '6 Ads on Google',
          b: 'Keywords Research',
          c: 'Optimization',
          d: 'Monthly Reporting',
          e: 'Google Business Manager',
          charges: '500 USD',
        },
      },
    ],
  },
  {
    id: 3,
    title: 'Google Ads',
    packages: [
      {
        id: 1,
        title: 'Silver Package',
        charges: '150 USD',
        details: {
          title: 'Silver Package',
          a: '12 Posts/Month',
          b: 'Content Creation',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '1 Ad',
          f: 'Management',
          charges: '150 USD',
        },
      },
      {
        id: 2,
        title: 'Golden Package',
        charges: '300 USD',
        details: {
          title: 'Golden Package',
          a: '20 Posts/Month',
          b: '1 Video',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '2 Ads',
          f: 'Management',
          g: 'Content Creation',
          charges: '300 USD',
        },
      },
      {
        id: 3,
        title: 'Platinum Package',
        charges: '500 USD',
        details: {
          title: 'Platinum Package',
          a: '30 Static Posts',
          b: '2 Videos',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '3 Ads',
          f: 'Management',
          g: 'Content Creation',
          charges: '500 USD',
        },
      },
    ],
  },
  {
    id: 4,
    title: 'Social Media Marketing, YouTube Ads and Google Ads',
    packages: [
      {
        id: 1,
        title: 'Silver Package',
        charges: '800 USD',
        details: {
          title: 'Silver Package',
          a: '12 Posts/Month',
          b: 'Content Creation',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '1 Ad',
          f: 'Management',
          charges: '800 USD',
        },
      },
      {
        id: 2,
        title: 'Golden Package',
        charges: '1200 USD',
        details: {
          title: 'Golden Package',
          a: '20 Posts/Month',
          b: '1 Video',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '2 Ads',
          f: 'Management',
          g: 'Content Creation',
          charges: '1200 USD',
        },
      },
      {
        id: 3,
        title: 'Platinum Package',
        charges: '3000 USD',
        details: {
          title: 'Platinum Package',
          a: '30 Static Posts',
          b: '2 Videos',
          c: 'Facebook and Instagram',
          d: 'Profile Creation and Optimization',
          e: '3 Ads',
          f: 'Management',
          g: 'Content Creation',
          charges: '3000 USD',
        },
      },
    ],
  },
];
