import React from 'react';
import './ContactForm.css';
import {MdLocationOn, MdEmail} from 'react-icons/md';
import {IoIosCall} from 'react-icons/io';
import {FaWhatsapp} from 'react-icons/fa';
import Aos from 'aos';
import {ContactFormSchema} from './ContactFormSchema';
import {toast} from 'react-toastify';
import Loading from '../../../../Loading/Loading';
import axios from 'axios';
import {useFormik} from 'formik';
import {useState} from 'react';
const initialValues = {
  name: '',
  email: '',
  subject: '',
  message: '',
};
function ContactForm() {
  Aos.init();
  Aos.refresh();

  const [loading, setLoading] = useState(false);
  const {values, errors, touched, handleBlur, handleChange, handleSubmit} =
    useFormik({
      initialValues,
      validationSchema: ContactFormSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        axios
          .post('https://thinko.herokuapp.com/contactUs', values)
          .then((res) => {
            toast.success('Your message has been successfully sent!');
            setLoading(false);
            action.resetForm();
          })
          .catch((err) => {
            console.log('Error', err.message);
            setLoading(false);
            toast.error('Something wrent wrong, please try again!');
          });
      },
    });
  return (
    <div id="contactForm">
      <h1 data-aos="zoom-in">
        WRITE US A <span>MESSAGE</span>
      </h1>
      <div className="formContainer">
        <div className="left-box">
          <form>
            <div className="inputField" data-aos="fade-right">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name ? (
                <p className="form-error">{errors.name}</p>
              ) : null}
            </div>
            <div className="inputField mt-3" data-aos="fade-right">
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <p className="form-error">{errors.email}</p>
              ) : null}
            </div>
            <div className="inputField mt-3" data-aos="fade-right">
              <label htmlFor="subject">Subject:</label>
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.subject && touched.subject ? (
                <p className="form-error">{errors.subject}</p>
              ) : null}
            </div>
            <div className="inputField mt-3" data-aos="fade-right">
              <label htmlFor="message">Message:</label>
              <textarea
                type="text"
                placeholder="Write Message"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.message && touched.message ? (
                <p className="form-error">{errors.message}</p>
              ) : null}
            </div>
            <button data-aos="fade-up" onClick={handleSubmit}>
              {loading ? <Loading /> : <>SEND MESSAGE</>}
            </button>
          </form>
        </div>
        <div className="right-box">
          <div data-aos="fade-left">
            <h3>CONTACT DETAILS</h3>
            <p>
              We are working in USA. We provide all services regarding
              digital marketing,web development,software development,garaphic
              designing,wordpress development,marketing consultency,data
              managment and analytics or call center etc.
            </p>
          </div>
          <div data-aos="fade-left">
            <h3>ADDRESS</h3>
            <div>
              <li>
                <MdLocationOn className="icon" />
                <a
                  href="https://goo.gl/maps/JuKJbS2aBkf6GxLo7"
                  target="_blank"
                  rel="noreferrer"
                >
                  144-43 BARCLAYS AVE FLUSHING NY 11354
                </a>
              </li>
              <li>
                <IoIosCall className="icon" />
                <a href="tel: ++16465127073" target="_blank" rel="noreferrer">
                  +1 (646) 512-7073
                </a>
              </li>
              <li>
                <FaWhatsapp className="icon" />
                <a
                  href="https://wa.me/+16465127073"
                  target="_blank"
                  rel="noreferrer"
                >
                  +1 (646) 512-7073
                </a>
              </li>
              <li>
                <MdEmail className="icon" />
                <a
                  href="mailTo: contact@mhglobalcommunicationllc.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact@mhglobalcommunicationllc.com
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
