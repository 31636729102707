import React, { useState } from "react";
import "./TechnologyStack.css";
import Aos from "aos";
import { TechnologyStackApi } from "./TechnologyStackApi";

function TechnologyStack() {
  Aos.init();
  Aos.refresh();

  const [activeBtn, setActiveBtn] = useState(1);
  const activeFilterBtn = (index) => {
    setActiveBtn(index);
  };
  const [Items, setItems] = useState(TechnologyStackApi);
  const filterItems = (categItem) => {
    const afterfilter = TechnologyStackApi.filter((item) => {
      return item.category === categItem;
    });
    setItems(afterfilter);
  };

  const allBtn = () => {
    // filterItems("all");
    setItems(TechnologyStackApi);
    activeFilterBtn(1);
  };
  const webBtn = () => {
    filterItems("web");
    activeFilterBtn(2);
  };
  const mobileBtn = () => {
    filterItems("mobile");
    activeFilterBtn(3);
  };
  const uxBtn = () => {
    filterItems("ux");
    activeFilterBtn(4);
  };

  return (
    <div id="techStack">
      <h1 data-aos="zoom-in">
        OUR TECHNOLOGY <span>STACK</span>
      </h1>
      <div className="filterButtons" data-aos="zoom-in">
        <button
          className={activeBtn === 1 ? "btns activeBtn" : "btns"}
          onClick={allBtn}
        >
          All
        </button>
        <button
          className={activeBtn === 2 ? "btns activeBtn" : "btns"}
          onClick={webBtn}
        >
          WEB
        </button>
        <button
          className={activeBtn === 3 ? "btns activeBtn" : "btns"}
          onClick={mobileBtn}
        >
          MOBILE
        </button>
        <button
          className={activeBtn === 4 ? "btns activeBtn" : "btns"}
          onClick={uxBtn}
        >
          UI/UX
        </button>
      </div>
      <div className="techContainer">
        {Items.map((data, index) => (
          <img src={data.logo} alt="" data-aos="zoom-in" key={index} />
        ))}
      </div>
    </div>
  );
}

export default TechnologyStack;
