import React from "react";
import "./CompanyMember.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { TeamMembersApi } from "./TeamMembersApi";

function CompanyMember() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <div id="companyMember">
        <h1 data-aos="zoom-in">
          MEET WITH OUR <span>TEAM</span>
        </h1>
        <div className="testi" data-aos="zoom-in">
          <Swiper
            modules={[Pagination, Autoplay]}
            slidesPerView={6}
            spaceBetween={10}
            grabCursor={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
                slidesPerGroup: 4,
              },
              800: {
                slidesPerView: 5,
                spaceBetween: 10,
                slidesPerGroup: 5,
              },
            }}
          >
            {TeamMembersApi.map((data, index) => (
              <SwiperSlide key={index}>
                <div className="client-det">
                  <img src={data.pic} alt="" />
                  <h5>{data.name}</h5>
                  <p>{data.role}</p>
                  <div className="social">
                    <NavLink to="#">
                      <FaFacebook className="sicon" />
                    </NavLink>
                    <NavLink to="#">
                      <AiOutlineWhatsApp className="sicon" />
                    </NavLink>
                    <NavLink to="#">
                      <AiOutlineInstagram className="sicon" />
                    </NavLink>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default CompanyMember;
