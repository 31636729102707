export const buttons = [
  {
    id: 1,
    title: "On page SEO",
    a: "Keywords",
    b: "Hashtags",
    c: "Content",
    d: "Blogs",
    package: "SEO",
  },
  {
    id: 2,
    title: "Off page SEO",
    a: "Web 2.0",
    b: "Link building",
    c: "Guest posting",
    package: "SEO",
  },
  {
    id: 3,
    title: "Technical SEO",
    a: "Website speed",
    b: "Data management",
    package: "SEO",
  },
  {
    id: 4,
    title: "Full package",
    a: "On page",
    b: "Off page",
    c: "Technical SEO",
    package: "SEO",
  },
];

