import client1 from "../../assets/clients/client1.png";
import client2 from "../../assets/clients/client2.png";
import client3 from "../../assets/clients/client3.png";
import client4 from "../../assets/clients/client4.png";
import client5 from "../../assets/clients/client5.png";
import client6 from "../../assets/clients/client6.png";
import client7 from "../../assets/clients/client7.jpg";
import client8 from "../../assets/clients/client8.png";
import client9 from "../../assets/clients/client9.png";
import client10 from "../../assets/clients/client10.png";
import client11 from "../../assets/clients/client11.png";
import client12 from "../../assets/clients/client12.png";

export const OurClientsApi = [
  {
    pic: client10,
    name: "Bharmar eCommerce Store",
  },
  {
    pic: client11,
    name: "Inrealtor.pk",
  },
  {
    pic: client12,
    name: "Panthar Dispatch Services",
  },
  {
    pic: client1,
    name: "Clear Sight Health",
  },
  {
    pic: client2,
    name: "Drexel University",
  },
  {
    pic: client3,
    name: "Concept Elements LLC",
  },
  {
    pic: client4,
    name: "ABC Services of Maryland",
  },
  {
    pic: client5,
    name: "Blue Streak Math",
  },
  {
    pic: client6,
    name: "Klang Kuppler",
  },
  {
    pic: client7,
    name: "Terra Virgo Corporate",
  },
  {
    pic: client8,
    name: "BlindTune",
  },
  {
    pic: client9,
    name: "Elehost Web Design",
  },
];
