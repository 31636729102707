import React from 'react';
import './DigitalMarketing.css';
import {NavLink} from 'react-router-dom';
import Aos from 'aos';
import DigitalMarketinDetails from './DigitalMarketinDetails/DigitalMarketinDetails';
import {Helmet} from 'react-helmet';

function DigitalMarketing() {
  Aos.init();
  Aos.refresh();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Digital Marketing | MH Global Communication LLC</title>
        <meta
          name="description"
          content="MH Global Communication LLC offers professional web development and provides consulting services in the area of Digital Marketing and
          also provides automated and IT-enabled solutions to businesses across the globe. We provide a wide range of consulting services"
        />
        <meta
          name="keywords"
          content="MH Global Communication LLC, digitalmarketing, digitalmarketingservice, webdevelopment, appdevelopment, itservices, seoservices, sales, business, marketingonline, marketingagency, digitalmarketingexperts, socialmediamarketing, socialmediamarketingagency, seo, reactjs, frontendwebdevelopment, frontenddeveloper, staticwebsite, ecommercewebsite, ecommercestore, software, graphicdesiging, logo, logodesign, bannerdesign, posterdesign, contentwriting, bootstrap, tailwindcss, html5, css3, html, css, nodejs, expressjs, reactnative, mongodb, solutions allitservices, smm,"
        />
        <link rel="canonical" href="http://mhglobalcommunicationllc.com" />
      </Helmet>
      <div id="digMar">
        <div className="overlay">
          <div className="breadcrumb">
            <h1 data-aos="fade-down">DIGITAL MARKETING</h1>
            <div className="d-flex">
              <NavLink to="/" data-aos="fade-right">
                HOME
              </NavLink>
              <div className="ms-2">-</div>
              <NavLink to="/services" className="ms-2" data-aos="fade-right">
                SERVICES
              </NavLink>
              <div className="ms-2">-</div>
              <p className="actv ms-2" data-aos="fade-left">
                DIGITAL MARKETING
              </p>
            </div>
          </div>
        </div>
      </div>
      <DigitalMarketinDetails />
    </>
  );
}

export default DigitalMarketing;
