export const buttons = [
  {
    id: 1,
    title: 'Wordpress & Shopify',
    a: 'E-commerce',
    b: 'Blogging Website',
    c: 'Services Website',
    packages: [
      {
        id: 1,
        title: 'Silver Package',
        charges: '250 USD',
        details: {
          title: 'Silver Package',
          a: '15 Products',
          b: 'Domain',
          c: 'Hosting',
          d: 'Professional Email',
          e: '100 per Product',
          f: '500 for SEO',
          charges: '750 USD',
        },
      },
    ],
  },
  {
    id: 2,
    title: 'Custom Development',
    a: 'Custom Design',
    b: 'Loading In',
    c: 'Multiple Languages',
    packages: [
      {
        id: 1,
        title: 'Silver Package',
        charges: '250 USD',
        details: {
          title: 'Silver Package',
          a: '15 Products',
          b: 'Domain',
          c: 'Hosting',
          d: 'Professional Email',
          e: '100 per Product',
          f: '500 for SEO',
          charges: '750 USD',
        },
      },
    ],
  },
];
