export const TechnologyStackApi = [
  //Web
  {
    id: 1,
    logo: "https://img.icons8.com/color/144/000000/html-5--v1.png",
    category: "web",
  },
  {
    id: 2,
    logo: "https://img.icons8.com/color/144/000000/css3.png",
    category: "web",
  },
  {
    id: 3,
    logo: "https://img.icons8.com/color/144/000000/bootstrap.png",
    category: "web",
  },
  {
    id: 4,
    logo: "https://img.icons8.com/color/144/000000/react-native.png",
    category: "web",
  },
  {
    id: 5,
    logo: "https://img.icons8.com/color/144/000000/tailwind_css.png",
    category: "web",
  },
  {
    id: 6,
    logo: "https://img.icons8.com/color/144/000000/nodejs.png",
    category: "web",
  },
  {
    id: 7,
    logo: "https://img.icons8.com/color/144/000000/mongodb.png",
    category: "web",
  },
  {
    id: 8,
    logo: "https://img.icons8.com/offices/30/000000/php-logo.png",
    category: "web",
  },
  {
    id: 9,
    logo: "https://img.icons8.com/color/144/000000/wordpress.png",
    category: "web",
  },
  {
    id: 10,
    logo: "https://img.icons8.com/color/144/000000/shopify.png",
    category: "web",
  },
  {
    id: 11,
    logo: "https://img.icons8.com/color/144/000000/figma--v1.png",
    category: "web",
  },
  {
    id: 12,
    logo: "https://img.icons8.com/color/144/000000/adobe-photoshop--v1.png",
    category: "web",
  },
  {
    id: 13,
    logo: "https://img.icons8.com/color/144/000000/firebase.png",
    category: "web",
  },
  {
    id: 14,
    logo: "https://img.icons8.com/color/144/000000/amazon-web-services.png",
    category: "web",
  },
  {
    id: 15,
    logo: "https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/96/000000/external-netlify-a-cloud-computing-company-that-offers-hosting-and-serverless-backend-services-for-static-websites-logo-shadow-tal-revivo.png",
    category: "web",
  },
  {
    id: 16,
    logo: "https://img.icons8.com/color/144/000000/heroku.png",
    category: "web",
  },
  {
    id: 17,
    logo: "https://img.icons8.com/color/144/000000/javascript--v1.png",
    category: "web",
  },

  // Mobile
  {
    id: 18,
    logo: "https://img.icons8.com/color/144/000000/react-native.png",
    category: "mobile",
  },
  {
    id: 19,
    logo: "https://img.icons8.com/color/144/000000/javascript--v1.png",
    category: "mobile",
  },
  {
    id: 20,
    logo: "https://img.icons8.com/color/144/000000/firebase.png",
    category: "mobile",
  },
  {
    id: 21,
    logo: "https://img.icons8.com/color/144/000000/amazon-web-services.png",
    category: "mobile",
  },
  {
    id: 22,
    logo: "https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/96/000000/external-netlify-a-cloud-computing-company-that-offers-hosting-and-serverless-backend-services-for-static-websites-logo-shadow-tal-revivo.png",
    category: "mobile",
  },
  {
    id: 23,
    logo: "https://img.icons8.com/color/144/000000/heroku.png",
    category: "mobile",
  },
  {
    id: 24,
    logo: "https://img.icons8.com/color/144/000000/nodejs.png",
    category: "mobile",
  },
  {
    id: 25,
    logo: "https://img.icons8.com/color/144/000000/mongodb.png",
    category: "mobile",
  },
  {
    id: 26,
    logo: "https://img.icons8.com/color/144/000000/java-coffee-cup-logo--v1.png",
    category: "mobile",
  },

  //  UI/UX
  {
    id: 27,
    logo: "https://img.icons8.com/color/144/000000/figma--v1.png",
    category: "ux",
  },
  {
    id: 28,
    logo: "https://img.icons8.com/color/144/000000/adobe-photoshop--v1.png",
    category: "ux",
  },
  {
    id: 29,
    logo: "https://img.icons8.com/color/144/000000/adobe-xd--v1.png",
    category: "ux",
  },
  {
    id: 30,
    logo: "https://img.icons8.com/color/144/000000/bootstrap.png",
    category: "ux",
  },
  {
    id: 31,
    logo: "https://img.icons8.com/cute-clipart/128/000000/canva.png",
    category: "ux",
  },
];
