import * as Yup from "yup";

export const ContactUsModal1Schema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  city: Yup.string().required("Please enter your city"),
  phone: Yup.number()
    .typeError("Field should be a number!")
    .required("Please enter phone number"),
  webUrl: Yup.string("Please enter your website url"),
});
