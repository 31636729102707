import React from "react";
import "./DigitalMarketinDetails.css";
import { buttons } from "../DigitalMarketingApi";
import { useNavigate } from "react-router-dom";
import Aos from "aos";

function DigitalMarketinDetails() {
  Aos.init();
  Aos.refresh();
  const Navigate = useNavigate();
  const sendData = (data) => {
    Navigate("/digital-marketing-packages", {
      state: data,
    });
  };
  return (
    <div id="diMarDetails">
      <h1>
        DIGITAL MARKETING <span>SERVICES</span>
      </h1>
      <div className="diContainer">
        {buttons.map((data, index) => (
          <div className="cards" key={index}>
            <h5>{data.title}</h5>
            <button onClick={() => sendData(data)}>See Packages</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DigitalMarketinDetails;
